<template>
    <ASelect
        mode="multiple"
        v-model:value="valueState"
        placeholder="Pilih Gudang"
        allow-clear
        show-search
        :not-found-content="state.loading ? undefined : 'Tidak ditemukan'"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.params.q = null)"
        @select="() => (state.params.q = null)"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true"
        style="width:300px">
        <ASelectOption v-for="item in state.data" :key="item.id" :label="LabelOption(item)">
            <span v-html="highlight(LabelOption(item))"></span>
        </ASelectOption>
        <template v-if="state.loading" #notFoundContent>
            <ASpin size="small" />
        </template>
    </ASelect>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, keyBy, merge, pickBy, values, isNumber, find, isArray, some, filter, includes } from 'lodash'

/**
 * Docs endpoint
 * https://documenter.getpostman.com/view/20162021/2s8YsozvBw#8d681156-60ab-4ea7-8d85-424f1df1f5ff
 **/
export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
        region: {
            type: [String, Number, Array],
            default: () => null,
        },
        area: {
            type: [String, Number, Array],
            default: () => null,
        },
        kota: {
            type: [String, Number, Array],
            default: () => null,
        },
        propinsi: {
            type: [String, Number, Array],
            default: () => null,
        },
        distributor: {
            type: [String, Number, Array],
            default: () => null,
        },
        showCode: {
            default: false,
            type: Boolean,
        },
        joinOptions: {
            type: [Array],
            default: () => [],
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            endpoint: `/api/filter/gudang-by-role`,
            loading: false,
            data: [],
            restore: [],
            params: {
                q: '',
                region: props.region,
                area: props.area,
                kota: props.kota,
                provinsi: props.propinsi,
                distributor: props.distributor,
            },
        })

        const fetchData = () => {
            state.loading = true
            apiClient.get(state.endpoint, { params: pickBy(state.params) })
                .then(({ data }) => {
                    const options = props.joinOptions.filter(item => item.id !== undefined)
                    if (options.length > 0) {
                        data = values(merge(keyBy(options, 'id'), keyBy(data, 'id')))
                    }
                
                    state.data = data
                })
                .finally(() => {
                    state.loading = false
                })
        }

        const LabelOption = (item) => {
            let labels = []

            if (props.showCode) {
                labels.push(item.code)
            }

            labels.push(item.name)
            
            return labels.join(' - ')
        }

        onMounted(() => {
            fetchData()
        })

        // manipulasi data
        watch(() => props.value, () => {
            if (isArray(props.value)) {
                // Hapus item dari restore yang tidak ada di value
                state.restore = filter(state.restore, item => includes(props.value, item.id))

                // Tambahkan item dari response ke restore jika id ada di value dan belum ada di restore
                props.value.forEach(id => {
                    if (!state.restore.some(item => item.id === id)) {
                        const itemToAdd = state.data.find(item => item.id === id)
                        if (itemToAdd) state.restore.push(itemToAdd)
                    }
                })
            }
        })

        // manipulasi data
        watch(() => props.value, () => {
            if (isArray(props.value)) {
                props.value.forEach(id => {
                    if (!state.data.some(item => item.id === id)) {
                        const itemToRestore = state.restore.find(item => item.id === id)
                        if (itemToRestore) state.data.push(itemToRestore)
                    }
                })
            }
        })

        watch(() => props.region, () => {
            state.params.region = props.region
        })

        watch(() => props.area, () => {
            state.params.area = props.area
        })

        watch(() => props.kota, () => {
            state.params.kota = props.kota
        })

        watch(() => props.propinsi, () => {
            state.params.provinsi = props.propinsi
        })

        watch(() => props.distributor, () => {
            state.params.distributor = props.distributor
        })

        watch(state.params, debounce(() => {
            fetchData()
        }, 300))

        const onSearch = value => {
            state.params.q = value
        }

        const highlight = value => {
            return value.replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        return {
            state,
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
            LabelOption,
        }
    },
}
</script>